import { 
    ColumnMapping, 
    SchemaColumn,
    ValueSource,
    ColumnSource
} from "../Schema";

/** getDuplicateColumnNames 
 * @souceMappings source mappings of columns
 * 
 * For each column, if its index already exists in the mapping
 * and its name has not been added to the list of duplicate names, add it. 
*/

export const getDuplicateColumnNames = (sourceMappings : ColumnMapping[]) => {

    let duplicates : string[] = [];
    let allCols : number[] = [];

    for(let c of sourceMappings){
        
        if(c.source.type !== 'column' )
            continue;
        
        const colName = (c.source as ColumnSource).name;
        const colIndex = (c.source as ColumnSource).index;

        if(allCols.indexOf(colIndex) !== -1 && duplicates.indexOf(colName) == -1){
            duplicates = [...duplicates, colName];
        }

        allCols = [...allCols, colIndex];
    }

    return duplicates;
}

/**
 * isRequired
 * Check if column is required
 * @param col 
 */
export const isRequired = (col : SchemaColumn) : boolean =>
    col.validations && col.validations.some(v => v.type === 'required');

/**
 * Get a schema column by ID
 * @param schemaColumns 
 * @param id 
 */    
export const getSchemaColumnById = (schemaColumns : SchemaColumn[], id : string) =>
    schemaColumns.find(schemaCol => schemaCol.id == id );

export interface validationResults {
    requiredColumnNamesWithNoValidSource : string[],
    requiredColumnNamesWithNoDefaultValue : string[]
}

/**
 * Validate that required columns have a source column or default value 
 * @param sourceMappings 
 * @param schemaColumns 
 */
export const validateColumns = (
    sourceMappings : ColumnMapping[],
    schemaColumns : SchemaColumn[]
) : validationResults => {
    //required cols with no column index
    let noCol : string[] = [];
    
    //required cols marked as default value with no value
    let noDefVal : string[] = [];

    for(let c of sourceMappings){

        const schemaCol = getSchemaColumnById(schemaColumns, c.destinationColumnId);
    
        //Skip if column is not required
        if(!isRequired(schemaCol))
            continue;

        if(c.source.type === 'doNotMap')  
            noCol = [...noCol, schemaCol.name];
        
        if(c.source.type === 'value' && (
            (c.source as ValueSource).value === undefined || 
            (c.source as ValueSource).value === '')
        )
            noDefVal = [...noDefVal, schemaCol.name];
    
    }
    
    return {
        requiredColumnNamesWithNoValidSource: noCol,
        requiredColumnNamesWithNoDefaultValue: noDefVal
    }
}