<script>
import ColumnDropdown from './ColumnDropdown.svelte';
import {isRequired} from '../lib/schema-utils';
export let schema = {};
export let fileInfo = {};
export let guessedColumnMappings = {};
export let columnMappings = [];
export let hasHeader = true;
export let onFileFieldSelect = () => {};

let columns = schema.columns.map((col) => ({
    id: col.id,
    name: col.name,
    description: col.description,
    defaultValue: '',
    validations: col.validations,
    selectedColumn: {}
}));

$: fileHeaders = hasHeader ? fileInfo.firstRows : fileInfo.firstRows[0] ? [getFillerHeaders(fileInfo.firstRows[0].length), ...fileInfo.firstRows] : [];

//TODO: Move this a library port to TS
export function getMappings() {
    let mappings = columns.map(col => {
        let type = col.selectedColumn.type;
        if (typeof type === 'undefined') {
            type = 'doNotMap';
        }

        const mappingSource = { type: type };
        switch (type) {
            case 'column':
                mappingSource.index = col.selectedColumn.index;
                mappingSource.name = col.selectedColumn.name;
                break;
            case 'value':
                mappingSource.value = col.defaultValue;
                break;
            case 'doNotMap':
            default:
                break;
        }

        const mapping = {
            destinationColumnId: col.id,
            source: mappingSource
        }
        return mapping;
    });
    return mappings;
}

function getFillerHeaders(numHeaders) {
    let headers = [];
    for (let i = 0; i < numHeaders; ) {
        headers.push(`Column ${++i}`);
    }
    return headers;
}

</script>

<style>.divTable {
  display: table !important;
  width: 100% !important;
  table-layout: fixed !important;
}

.divTableRow {
  display: table-row !important;
}

.divTableCell,
.divTableHead {
  /* border: 1px solid #999999; */
  display: table-cell !important;
  /* padding: 3px 10px; */
}

.divTableHead {
  position: sticky !important;
  top: 0 !important;
  background-color: white !important;
  z-index: 1 !important;
}

.divTableCell {
  position: relative !important;
  padding-right: .3em !important;
}

.divTableHeading {
  /* background-color: #EEE; */
  display: table-header-group !important;
  font-weight: bold !important;
}

.divTableBody {
  display: table-row-group !important;
}

.required {
  color: red !important;
}

input[type=text] {
  width: 100% !important;
  vertical-align: baseline !important;
}

[tooltip] {
  position: relative !important;
}

[tooltip]::before {
  content: "" !important;
  position: absolute !important;
  top: -6px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  border-width: 4px 6px 0 6px !important;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent !important;
  z-index: 99 !important;
  opacity: 0 !important;
}

[tooltip]::after {
  content: attr(tooltip) !important;
  position: absolute !important;
  left: 50% !important;
  top: -6px !important;
  transform: translateX(-50%) translateY(-100%) !important;
  background: rgba(0, 0, 0, 0.7) !important;
  text-align: center !important;
  color: #fff !important;
  padding: 4px 2px !important;
  font-size: 12px !important;
  min-width: 80px !important;
  border-radius: 5px !important;
  pointer-events: none !important;
  padding: 4px 5px !important;
  z-index: 99 !important;
  opacity: 0 !important;
}

/* [tooltip-position="left"]::before {
 left: 0%;
 top: 50%;
 margin-left: -12px;
 transform: translatey(-50%) rotate(-90deg);
}
[tooltip-position="top"]::before {
 left: 50%;
}
[tooltip-position="bottom"]::before {
 top: 100%;
 margin-top: 8px;
 transform: translateX(-50%) translatey(-100%) rotate(-180deg);
} */

[tooltip-position="right"]::before {
  left: 100% !important;
  top: 50% !important;
  margin-left: 1px !important;
  transform: translatey(-50%) rotate(90deg) !important;
}

/* [tooltip-position="left"]::after {
 left: 0%;
 top: 50%;
 margin-left: -8px;
 transform: translateX(-100%) translateY(-50%);
}
[tooltip-position="top"]::after {
 left: 50%;
}
[tooltip-position="bottom"]::after {
 top: 100%;
 margin-top: 8px;
 transform: translateX(-50%) translateY(0%);
} */

[tooltip-position="right"]::after {
  left: 100% !important;
  top: 50% !important;
  margin-left: 8px !important;
  transform: translateX(0%) translateY(-50%) !important;
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1 !important;
}</style>

<div class="delimited-form">
    <div class="divTable">
        <div class="divTableHeading">
            <div class="divTableRow">
                <div class="divTableHead" style="width:25% !important">Expected Field</div>
                <div class="divTableHead" style="width:42% !important">File Field</div>
                <div class="divTableHead" style="width:33% !important">Default Value</div>
            </div>
        </div>
        <div class="divTableBody">
            {#each columns as col, index (col.id)}
            <div class="divTableRow">
                <div class="divTableCell">{col.name} {#if isRequired(col)}<span class="required">*</span>{/if} {#if col.description}<span tooltip="{col.description}" tooltip-position="right"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" style="vertical-align: -.2125rem"><path fill="none" d="M0 0h24v24H0z"/><path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"/></svg></span>{/if}</div>
                <div class="divTableCell">
                    <ColumnDropdown 
                        bind:selectedColumn={col.selectedColumn} 
                        required={isRequired(col)} 
                        fileColumns={fileHeaders} 
                        guessedIndex={guessedColumnMappings[col.id]} 
                        defaultValue={col.defaultValue}
                        onSelect={col => onFileFieldSelect(col, index)} />
                </div>
                <div class="divTableCell">
                    <input type="text" bind:value={col.defaultValue}>
                </div>
            </div>
            {/each}
        </div>
    </div>
</div>