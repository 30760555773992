export interface Schema {
    id: string;
    version: string;
    inputOptions: InputOptions;
    columns: Array<SchemaColumn>;
}

export interface InputOptions {
    commentChar: string;
    commentsAllowed: boolean;
    delimiter: string;
    encoding: string;
    escapeChar: string;
    headersIncluded: HeaderOptions;
    newlineChar: string;
    quoteChar: string;
}

export interface Mapping {
    id: string;
    version: string;
    schemaId: string;
    fileHasHeaderSelection?: boolean;
    columnMappings: Array<ColumnMapping>;
}

export enum HeaderOptions {
    UploaderChooses = 'uploaderChooses',
    Always = 'always',
    Never = 'never'
}

export interface SchemaColumn {
    id: string;
    name: string;
    alternateNames: Array<string>;
    validations: Array<SchemaColumnValidation>;
}
export interface SchemaColumnValidation {
    type: string;
}

export interface ColumnMapping {
    destinationColumnId: string;
    source: ColumnMappingSource;
}

export interface ColumnMappingSource {
    type: 'column' | 'value' | 'doNotMap';
}

export interface ColumnSource extends ColumnMappingSource {
    index: number;
    name: string;
    defaultIfBlank?: string;
    defaultIfInvalid?: string;
}

export interface ValueSource extends ColumnMappingSource {
    value: string;
}

export interface DoNotMapSource extends ColumnMappingSource { }

export interface SelectedColumnInfo{
    index: number;
    name: string;
}