

export function injectStyleSheetText(css: string, document: Document) {
    const style = document.createElement('style');
    var head = document.head || document.getElementsByTagName('head')[0] || document.documentElement;
    style.textContent = css;

    head.appendChild(style);

    style.type = 'text/css';
    if ((<any>style).styleSheet){
      // This is required for IE8 and below.
      (<any>style).styleSheet.cssText = css;
    }
}