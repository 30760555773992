import { ColumnMapping } from "./Schema";
import { MappingStatus } from "./MappingStatus";
import { UploadStatus } from "./UploadStatus";

/** Object representing a front-end upload */
export class Upload {
  /** The first few rows of the file, parsed client-side */
  firstRows?: Array<Array<string>>;
  uppyFileId?: string;
  mappingState: {
    status: MappingStatus;
    data?: ColumnMapping[];
  } = {
      status: MappingStatus.NEW
    };
  uploadState: {
    status: UploadStatus;
    error?: string;
  } = {
      status: UploadStatus.NEW
    };
  progressQueryInterval?: NodeJS.Timeout;
  latestProgress?: any;
  constructor(public id: string) { }
}
