import { Uploader } from "./Uploader";
import { ImporterOptions } from "./ImporterOptions";
import { MappingModal } from "./MappingModal";

// Polyfill the fetch function (just needs to be done once)
import "whatwg-fetch";

/**
 * Orchestrates the importation of various upload buttons on the page.
 */
export class Importer {
    private uploaders: Array<Uploader> = [];
    private mappingModal: MappingModal;
    private options: ImporterOptions = {apiKey: null};
    private defaultOptions: ImporterOptions = {
        // Default public API key
        apiKey: 'HWYqVhHL1p3wH958igWKO8iEvORsy4M01DnUFStN',
        dataKeyName: 'delimited',
        apiUrl: 'https://api.delimited.dev/v1/',
        fileUploadUrl: 'https://files.delimited.dev/'
    };

    constructor(options: ImporterOptions) {
        this.options = Object.assign({}, this.defaultOptions, options);
        this.mappingModal = new MappingModal(this.options);
        this.init();
    }

    init() {
        console.debug(`Initializing Delimited importer...`);

        this.addCleanSlate();

        this.executeOnReady(() => {

            this.mappingModal.init();
    
            this.refreshUploaders(); 

        });    
    }

    refreshUploaders() {
        this.clearCurrentUploaders();
        const uploadElements = this.findUploadElements();
        this.uploaders =  uploadElements.map((el) => {
            var uploader = new Uploader(el, this.mappingModal, this.options);
            uploader.init();
            return uploader;
        });
    }

    clearCurrentUploaders() {
        this.uploaders.forEach(u => {
            u.remove();
        });
    }

    addCleanSlate() {
        let link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/cleanslate/0.10.1/cleanslate.min.css';
        link.integrity = 'sha256-afDmbeOUeIARyeDV/PU/Gg/rMquhexjEx4YSzZKttE8=';
        link.crossOrigin = 'anonymous';
        (document.head||document.getElementsByTagName('head')[0]).appendChild(link);
    }

    findUploadElements(): Array<HTMLElement> {
        let nodeList = document.querySelectorAll(`[data-${this.options.dataKeyName}]`);
        let elementList : Array<HTMLElement> = [];

        if (!nodeList || (nodeList && nodeList.length ===0)) {
            console.warn('Initialized Delimited importer library but could find no uploader widgets. Make sure to include one or more widgets on the page.');
        }

        if (nodeList) {
            for (let i = 0; i < nodeList.length; i++) {
                let node : Node = nodeList[i];

                // Make sure it's really an Element
                if (node.nodeType == Node.ELEMENT_NODE) {
                    elementList.push(node as HTMLElement);
                }
            }
        }

        return elementList;
    }

    executeOnReady(callback: any) {
        if ( document.readyState === 'complete' || document.readyState === 'interactive' ) {
            return callback();
        }
    
        if ( document.addEventListener ) {
            document.addEventListener( "DOMContentLoaded", callback, false );
        }
    }
}