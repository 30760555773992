<script>
export let fileColumns = [];
export let guessedIndex;
export let selectedColumn;
export let required = false;
export let defaultValue = '';
export let onSelect = () => {};

$: hasTypedDefault = defaultValue.length > 0;

$: columns = fileColumns[0].map((val, idx) => {
    const sampleData = [];
    for (var i = 1; i < fileColumns.length; i++) {
        sampleData.push(fileColumns[i][idx]);
    }
    return {
        index: idx,
        name: val,
        description: sampleData.filter(val => typeof val !== 'undefined' && val !== '').join(', ')
    };
});
let ignore = {
    name: 'Ignore',
    description: 'Do not provide a value for this column.'
}
let useDefault = {
    name: 'Use the Default Value',
    description: 'Use same typed value for every row.'
}
$: columnOptions = required ? [useDefault, ...columns] : [ignore, useDefault, ...columns];
$: guessedColumn = typeof guessedIndex !== 'undefined' ? columns[guessedIndex] : undefined;

let component;
let dropdownActive = false;
let userPickedCol;
$: selectedCol = typeof userPickedCol !== 'undefined' ? userPickedCol : hasTypedDefault ? useDefault : guessedColumn;
$: selectedColumn = selectedCol ? {
    type: selectedCol === ignore ? 'doNotMap' : 
            selectedCol === useDefault ? 'value' :
            'column',
    index: selectedCol.index,
    name: selectedCol.name
} : {};
$: labelTitle = selectedCol ? selectedCol.name : 'Select...';
</script>

<style>.wrapper-dropdown {
  /* Size and position */
  position: relative !important;
  width: 95% !important;
  padding: 5px !important;
  margin: 5px 0 !important;
  /* Styles */
  outline: none !important;
  cursor: pointer !important;
  border: 1px solid #9bc7de !important;
  box-sizing: border-box !important;
  /* Font-settings */
  font-weight: bold !important;
}

.wrapper-dropdown ul,
.wrapper-dropdown li {
  margin: 0 !important;
  padding: 0 !important;
}

.wrapper-dropdown:after {
  content: "" !important;
  width: 0 !important;
  height: 0 !important;
  position: absolute !important;
  right: 16px !important;
  top: 50% !important;
  margin-top: -6px !important;
  border-width: 6px 0 6px 6px !important;
  border-style: solid !important;
  border-color: transparent #9bc7de !important;
}

.wrapper-dropdown .dropdown {
  /* Size & position */
  position: absolute !important;
  top: 100% !important;
  left: -1px !important;
  /* Size */
  right: -80px !important;
  /* Size */
  padding-inline-start: 0 !important;
  list-style-type: none !important;
  /* Styles */
  background: #fff !important;
  font-weight: normal !important;
  /* Overwrites previous font-weight: bold; */
  overflow: auto !important;
  max-height: 200px !important;
  /* Hiding */
  opacity: 0 !important;
  height: 0 !important;
  pointer-events: none !important;
  cursor: pointer !important;
  box-shadow: black 8px 9px 14px -7px !important;
}

.wrapper-dropdown .dropdown li div {
  padding: 5px 5px !important;
  border-style: solid !important;
  border-color: #9bc7de !important;
  border-width: 0 1px 1px 1px !important;
  overflow: auto !important;
  max-height: 80% !important;
  cursor: pointer !important;
}

.wrapper-dropdown p {
  margin: 0 !important;
  cursor: pointer !important;
}

.wrapper-dropdown p.label-title {
  margin: 0 30px 0 0 !important;
  cursor: pointer !important;
}

.file-header-name {
  font-size: smaller !important;
}

.file-description {
  font-size: x-small !important;
  color: #9e9e9e !important;
  font-style: italic !important;
}

/* Hover state */

.wrapper-dropdown .dropdown li:hover div {
  background: #f3f8f8 !important;
}

/* Active state */

.wrapper-dropdown.active .dropdown {
  opacity: 1 !important;
  height: inherit !important;
  pointer-events: auto !important;
  z-index: 1 !important;
}

.wrapper-dropdown.active:after {
  border-color: #9bc7de transparent !important;
  border-width: 6px 6px 0 6px !important;
  margin-top: -3px !important;
}

.wrapper-dropdown.active {
  background: #9bc7de !important;
  background: linear-gradient(to right, #9bc7de 0%, #9bc7de 61%, #ffffff 88%, #ffffff 100%) !important;
}

.noselect {
  -webkit-touch-callout: none !important;
  /* iOS Safari */
  -webkit-user-select: none !important;
  /* Safari */
  -khtml-user-select: none !important;
  /* Konqueror HTML */
  -moz-user-select: none !important;
  /* Firefox */
  -ms-user-select: none !important;
  /* Internet Explorer/Edge */
  user-select: none !important;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.truncate {
  max-width: 270px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}</style>

<svelte:window on:click={e => dropdownActive = component.contains(e.target) ? dropdownActive : false} />

<div bind:this={component} class:active="{dropdownActive}" class="wrapper-dropdown" on:click="{e => dropdownActive = !dropdownActive}" tabindex="0">
    <p class="truncate label-title" title={labelTitle}>{labelTitle}</p>
    <ul class="dropdown">
        {#each columnOptions as column}
        <li class="noselect">
            <div on:click={e => (userPickedCol = column) && onSelect(column)}>
                <p class="file-header-name truncate" title={column.name}>{column.name}</p>
                <p class="file-description truncate" title={column.description}>{column.description}</p>
            </div>
        </li>
        {/each}
    </ul>
</div>