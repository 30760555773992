import modalHtml from "./html/MappingModal.html";
import modalCss from "./css/MappingModal.css";
import MicroModal from 'micromodal';
import { ImporterOptions } from "./ImporterOptions";
import { SchemaColumn, ColumnMapping, HeaderOptions, Mapping, SelectedColumnInfo, ColumnSource } from "./Schema";
import { UploadedFileData } from "./UploadedFileData";
import { generate as shortid } from "shortid";
import { injectStyleSheetText } from "./HtmlUtils";
import { MappingStatus } from "./MappingStatus";

import {getDuplicateColumnNames, validateColumns} from './lib/schema-utils';
import {joinWithAnd} from './lib/etc'

import MappingForm from './svelte/MappingForm.svelte';

export class MappingModal {
    element: HTMLElement;
    selectors: { [elementName: string]: string } = {
        spinner: '#delimited-modal-loading-spinner',
        uploadProgress: '#delimited-modal__upload-progress',
        mappingForm: '#delimited-modal-mapping-form',
        finishUploading: '#delimited-modal-finish-uploading',
        errors: '#delimited-modal__mapper-errors',
        warnings: '#delimited-modal__mapper-warnings',
        hasHeader: '#delimited-modal__has-headers',
        headerOption: '#delimited-modal_header-option'
    }
    elements: { [selector: string]: HTMLElement } = {}

    schemaId: string;
    uploadId: string;
    file: UploadedFileData;

    /**
     * The promise that represents success or failure of the modal.
     * Resolved when the user continues the upload. Rejected on error.
     * Cancelling the upload will resolve the promise with a status of cancelled.
     */
    modalPromise: Promise<{status: MappingStatus, data: ColumnMapping[]}>;

    private formComponent: any;

    private hasResolved: boolean = false;
    private modalPromiseResolve: (value?: {status: MappingStatus, data: ColumnMapping[]} | {status: MappingStatus, data: ColumnMapping[]}) => void;
    private modalPromiseReject: (reason?: any) => void;

    constructor(private options: ImporterOptions) { }

    init() {
        var existingModal = document.getElementById('delimited-modal-mapper');
        if (existingModal) {
            this.element = existingModal;
        } else {
            this.element = this.createElement();            
        }

        this.fillElements();
        this.elements.finishUploading.addEventListener('click', this.finishUploadingAll.bind(this));
        this.elements.hasHeader.addEventListener('change', this.toggleFileHasHeaders.bind(this));
        this.reset();
    }

    createElement() {
        const e = document.createElement('div');
        e.innerHTML = modalHtml;       
        const element = <HTMLElement>e.firstElementChild;
        document.body.insertAdjacentElement('beforeend', element);
        injectStyleSheetText(modalCss, document);
        return element;
    }

    fillElements() {
        var elementNames = Object.keys(this.selectors);
        for (var i = 0; i < elementNames.length; i++) {
            var elementName = elementNames[i];
            this.elements[elementName] = this.element.querySelector(this.selectors[elementName]);
        }
    }

    /**
     * Shows the mapping modal for a particular uploaded file.
     * 
     * @param uploadedFile The file that has been uploaded
     * @param onShowCallback Will be executed once the mapping modal is shown on screen
     * @param onCloseCallback Will be executed when the mapping modal is no longer shown
     * @returns { Promise<ColumnMapping[]>}
     *  The promise that represents success or failure of the modal.
     *  Resolved when the user continues the upload. Rejected on error.
     *  Cancelling the upload will resolve the promise with a null column mapping.
     */
    show(uploadedFile: UploadedFileData, onShowCallback?: () => void, onCloseCallback?: () => void) {
        this.showSpinner();
        this.reset();
        MicroModal.show(this.element.id, {
            awaitCloseAnimation: false,
            onClose: () => {
                if (!this.hasResolved) {
                    // If we haven't resolved by the time we close the modal, then
                    // treat it as cancelled (successfully resolve but with null)
                    this.resolve(MappingStatus.CANCELLED, null);
                }

                if (onCloseCallback) { onCloseCallback(); }
            }
        });

        if (onShowCallback) { onShowCallback(); }

        if (uploadedFile.schema.inputOptions.headersIncluded === HeaderOptions.UploaderChooses) {
            this.showHeaderToggle(true);
        } else {
            this.hideHeaderToggle();
        }

        this.hideSpinner();
        this.fillWithUploadedFileInfo(uploadedFile);

        return this.modalPromise;
    }

    showSpinner() {
        this.elements.spinner.classList.remove('hide');
    }

    hideSpinner() {
        this.elements.spinner.classList.add('hide');
    }

    showForm() {
        this.elements.mappingForm.classList.remove('hide');
    }

    hideForm() {
        this.elements.mappingForm.classList.add('hide');
    }

    disableSubmit() {
        (<HTMLButtonElement>this.elements.finishUploading).disabled = true;
    }

    enableSubmit() {
        (<HTMLButtonElement>this.elements.finishUploading).disabled = false;
    }

    showHeaderToggle(checked: boolean) {
        this.elements.headerOption.classList.remove('hide');
        (<HTMLInputElement>this.elements.hasHeader).checked = checked;
    }

    hideHeaderToggle() {
        this.elements.headerOption.classList.add('hide');
    }

    showError(error: string) {
        this.elements.errors.innerHTML = `<p>${error}</p>`;
        this.elements.errors.classList.remove('hide');
    }

    showWarning(error: string) {
        this.elements.warnings.innerHTML = `<p>${error}</p>`;
        this.elements.warnings.classList.remove('hide');
    }

    hideError() {
        this.elements.errors.classList.add('hide');
    }

    hideWarning() {
        this.elements.warnings.classList.add('hide');
    }

    reset() {
        this.hasResolved = false;
        this.modalPromise = new Promise((resolve, reject) => {
            this.modalPromiseResolve = resolve;
            this.modalPromiseReject = reject;
        });
        
        this.hideError();
        this.hideWarning();
        this.hideForm();
        this.elements.mappingForm.innerHTML = '';
    }

    fillWithUploadedFileInfo(file: UploadedFileData) {
        this.schemaId = file.schema.id;
        this.uploadId = file.uploadId;
        this.file = file;

        // Only guess mappings if the file has a header
        let hasHeader;
        switch (file.schema.inputOptions.headersIncluded) {
            case HeaderOptions.Always:
                hasHeader = true;
                break;
            case HeaderOptions.Never:
                hasHeader = false;
                break;
            case HeaderOptions.UploaderChooses:
                hasHeader = true;
                break;
        }

        let guessedColumnMappings = hasHeader ? this.guessColumnMapping(file) : {};
        var fileInfo = {
            subscribe: function(callback: (status: string, error?: string, bytesUploaded?: number, bytesTotal?: number) => void) {
                callback('Uploading...')
            },
            firstRows: file.firstRows
        };

        const context = {
            schema: file.schema,
            fileInfo: fileInfo,
            guessedColumnMappings: guessedColumnMappings,
            hasHeader: hasHeader,
            onFileFieldSelect: this.validateFileField.bind(this)
        };

        this.formComponent = new MappingForm({
            target: this.elements.mappingForm,
            props: context
        });

        this.showForm();
    }

    /**
     * Checks if selected source file field is already in use and shows warning
     * 
     * @param selectedColumn selected source file column name and index
     */
    validateFileField(selectedColumn : SelectedColumnInfo, selectedColumnIndex : number){

        //Get source mappings column indexes and add newly selected index
        const sourceMappings = this.formComponent.getMappings();
        
        //Looks like component bindings haven't fired yet, otherwise formComponent.getMappings() 
        //would be updated with newly selected column
        //Update it for our purposes 
        sourceMappings[selectedColumnIndex].source = {
            index: selectedColumn.index,
            name: selectedColumn.name,
            type: "column"
        };
        
        //Check for duplicates
        const duplicateColumnNames = getDuplicateColumnNames(sourceMappings);
        const duplicateColumnNamesStr = duplicateColumnNames.join(', ');
        const plural = duplicateColumnNames.length > 1;

        duplicateColumnNames.length ? 
            this.showWarning(`Warning: column${plural ? 's' : ''} ${duplicateColumnNamesStr} ${plural ? 'are' : 'is'} used multiple times.`) :
            this.hideWarning();
    
    }

    /**
     * Tries to match the columns that the user provided with the columns that
     * the schema expects. Starts with exact matches and then moves to alternate
     * names.
     * 
     * @param file The uploaded file data
     */
    guessColumnMapping(file: UploadedFileData) {
        const mappings: { [schemaColumnId: string]: number} = {};

        const exactMatchStrategy = (column: SchemaColumn, headers: Array<string>) => {
            for (let j = 0; j < headers.length; j++) {
                const uploadedHeader = headers[j];

                if (uploadedHeader === column.name) {
                    return j;
                }
            }

            return null;
        }

        const alternateNameMatchStrategy = (column: SchemaColumn, headers: Array<string>) => {
            for (let j = 0; j < headers.length; j++) {
                const uploadedHeader = headers[j];

                if (column.alternateNames.indexOf(uploadedHeader) !== -1) {
                    return j;
                }
            }

            return null;
        }

        const fileHeaders = file.firstRows[0];
        if (!fileHeaders) { return {}; }

        for (let i = 0; i < file.schema.columns.length; i++) {
            const column = file.schema.columns[i];
            let exactMatch = exactMatchStrategy(column, fileHeaders);
            let alternateMatch = alternateNameMatchStrategy(column, fileHeaders);
            const fileIndex = exactMatch !== null ? exactMatch : alternateMatch;
            if (fileIndex !== null) {
                mappings[column.id] = fileIndex;
            }
        }

        return mappings;
    }

    /**
     * Validate that required columns have a source column or default value
     */
    validateColumnsAll() : boolean {
        const sourceMappings = this.formComponent.getMappings();
        
        const results = validateColumns(sourceMappings, this.file.schema.columns);

        if(results.requiredColumnNamesWithNoDefaultValue.length === 0 &&
            results.requiredColumnNamesWithNoValidSource.length === 0)
            return true;
        
        let errorMsg = '';
        
        if(results.requiredColumnNamesWithNoDefaultValue.length)
            errorMsg += 
                'The following required fields are missing a default value: ' + 
                joinWithAnd(results.requiredColumnNamesWithNoDefaultValue) + '.';

        
        if(results.requiredColumnNamesWithNoValidSource.length)
            errorMsg += (errorMsg.length ? ' ' : '') +
                    'The following required fields are missing a source column or a default value: ' + 
                    joinWithAnd(results.requiredColumnNamesWithNoValidSource) + '.';
        
        this.showError('Error: ' + errorMsg);
        
        return false;
    }

    async finishUploadingAll() {
        
        //check for missing column mappings or default values
        if(this.validateColumnsAll() == false){
            return;
        }

        this.hideForm();
        this.disableSubmit();
        this.showSpinner();
        this.hideError();
        this.hideWarning();

        const userMappings = this.getUserMappings();
        var mappingPromises = userMappings.map(this.finishUploadingMapping, this);
        try {
            var results = await Promise.all(mappingPromises);
            var firstResponse = results[0];
            this.resolve(MappingStatus.SUCCESS, firstResponse);
            MicroModal.close(this.element.id);
        } catch (err) {
            const reason = `Could not finish uploading due to error: ${err}`;
            this.showError(reason);
            console.error(reason);
            // Don't reject on error since the user can still fix it and resolve properly
            // this.reject(reason);
        } finally {
            this.hideSpinner();
            this.showForm();
            this.enableSubmit();
        }
    }

    async finishUploadingMapping(userMapping: {
        uploadId: string;
        mapping: Mapping;
    }) {
        const response = await fetch(`${this.options.apiUrl}uploads/${userMapping.uploadId}/mapping`, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'omit',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': this.options.apiKey
            },
            redirect: 'follow',
            referrer: 'client',
            body: JSON.stringify(userMapping.mapping)
        });
        if (!response.ok) {
            let message = `Could not finish uploading (response ${response.status}).`;
            const text = await response.json();
            if (text) {
                message += ` ${text}`;
            }
            throw new Error(message);
        }
        return response.json();
    }

    getUserMappings() {
        // For now, since the modal supports just one file
        return [this.getUserMapping(this.uploadId, this.schemaId)];
    }

    getUserMapping(uploadId: string, schemaId: string) {
        const columnMappings = this.formComponent.getMappings();
        
        const uploadMapping: {uploadId: string, mapping: Mapping} = {
            uploadId: uploadId,
            mapping: {
                id: shortid(),
                version: '0.0.1',
                schemaId: schemaId,
                columnMappings: columnMappings
            }
        };

        if (this.file.schema.inputOptions.headersIncluded === HeaderOptions.UploaderChooses) {
            const hasHeaderElement = (<HTMLInputElement>this.elements.hasHeader);
            uploadMapping.mapping.fileHasHeaderSelection = hasHeaderElement.checked
        }

        return uploadMapping;
    }

    toggleFileHasHeaders(e: Event) {
        let checked = (<HTMLInputElement>e.currentTarget).checked;
        if (this.formComponent) {
            this.formComponent.$set({ hasHeader: checked });
        }
    }

    resolve(status: MappingStatus, mapping?: ColumnMapping[]) {
        if (!this.hasResolved) {
            this.modalPromiseResolve({status, data: mapping});
            this.hasResolved = true;
        }
    }

    reject(reason: string) {
        if (!this.hasResolved) {
            this.modalPromiseReject(reason);
            this.hasResolved = true;
        }
    }
}