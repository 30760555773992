import { Importer } from './Importer';
import { ImporterOptions } from "./ImporterOptions";
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({ 
        dsn: process.env.DELIMITED_SENTRY_DSN,
        whitelistUrls: [
            /https?:\/\/static.delimited.dev/,
            /https?:\/\/localhost:8080/
        ],
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
            'originalCreateNotification',
            'canvas.contentDocument',
            'MyApp_RemoveAllHighlights',
            'http://tt.epicplay.com',
            'Can\'t find variable: ZiteReader',
            'jigsaw is not defined',
            'ComboSearch is not defined',
            'http://loading.retry.widdit.com/',
            'atomicFindClose',
            // Facebook borked
            'fb_xd_fragment',
            // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
            // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
            'bmi_SafeAddOnload',
            'EBCallBackMessageReceived',
            // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
            'conduitPage',
            // Generic error code from errors outside the security sandbox
            // You can delete this if using raven.js > 1.0, which ignores these automatically.
            'Script error.'
          ],
        blacklistUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /127\.0\.0\.1:4001\/isrunning/i,  // Cacaoweb
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
          ]
    });
}

let instance: Importer;

/**
 * Initializes and returns a new importer object.
 * Finds and initializes all upload elements on the page.
 *
 * @param options
 */
export const init = (options?: ImporterOptions): Importer => {
    if (typeof instance === 'undefined') {
        instance = new Importer(options);
    }
    
    return instance;
};

/**
 * Clears any current uploaders and searches the page again to initialize any
 * that are present. Useful for SPAs.
 */
export const refreshUploaders = () => {
    if (typeof instance === 'undefined') {
        throw new Error('Must initialize the importer using `init` before refreshing the uploaders.');
    } else {
        instance.refreshUploaders();
    }
}